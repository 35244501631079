import request from "../utils/request";

export function loginByStudent(student_email, student_pwd) {
    return request.post("./index.php", {
        function: "loginByStudentV1",
        student_email,
        student_pwd,
    });
}

export function postRequest(params) {
    return request.post("./index.php", params);
}

//以下为Activity的界面
export function getMyActivities(student_id) {
    return request.post("./index.php", {
        function: "getMyActivities",
        student_id,
    });
}

export function updateActivity(params) {
    return request.post("./index.php", {
        function: "updateActivity",
        id: params.id,
        student_id: params.student_id,
        type: params.type,
        position: params.position,
        org: params.org,
        desc: params.desc,
        hours_peer_week: params.hours_peer_week,
        start_time: params.start_time,
        end_time: params.end_time,
        attachmentUrl: params.attachmentUrl,
        status: params.status,
    });
}

//以下为ActivityOption的界面
export function getActivityAdvices(key_word) {
    return request.post("./index.php", {
        function: "getActivityAdvices",
        key_word,
    });
}

export function getReviewEntriesByUserId(user_id) {
    return request.post("./index.php", {
        function: "getReviewEntriesByUserId",
        user_id,
    });
}

export function updateReviewEntry(params) {
    return request.post("./index.php", {
        function: "updateReviewEntry",
        id: params.id,
        category_id: params.category_id,
        title: params.title,
        intro: params.intro,
        user_id: params.user_id,
        open_status: params.open_status,
        entry_type: params.entry_type,
        application_type: params.application_type,
        team_type: params.team_type,
        tags: params.tags,
        feature_award_id: params.feature_award_id
    });
}

export function getActivityOptions() {
    return request.post("./index.php", {
        function: "getActivityOptions",
    });
}
export function getActivityOption(id) {
    return request.post("./index.php", {
        function: "getActivityOption",
        id,
    });
}
export function getActivityOptionsByPage(student_id, star, pageSize, pageNum) {
    return request.post("./index.php", {
        function: "getActivityOptionsByPage",
        student_id,
        star,
        pageSize,
        pageNum,
    });
}
export function getActivityOptionsByPageV1(
    student_id,
    pageSize,
    pageNum,
    request_type
) {
    return request.post("./index.php", {
        function: "getActivityOptionsByPageV1",
        student_id,
        pageSize,
        pageNum,
        request_type,
    });
}

export function getActivityOptionStandards(option_id) {
    return request.post("./index.php", {
        function: "getActivityOptionStandards",
        option_id,
    });
}

export function saveStandardApplication(
    student_id,
    option_id,
    standard_id,
    activity_id,
    entry_id
) {
    return request.post("./index.php", {
        function: "saveStandardApplication",
        student_id,
        option_id,
        standard_id,
        activity_id,
        entry_id,
    });
}

export function getProjects() {
    return request.post("./index.php", {
        function: "getProjects",
    });
}

export function getReviewEntry(id) {
    return request.post("./index.php", {
        function: "getReviewEntry",
        id,
    });
}

export function getEntryPeoplesByReviewEntryId(review_entry_id) {
    return request.post("./index.php", {
        function: "getEntryPeoplesByReviewEntryId",
        review_entry_id,
    });
}

export function updateEntryPeople(params) {
    return request.post("./index.php", {
        function: "updateEntryPeople",
        ...params,
    });
}
export function removeEntryPeople(review_entry_id, entry_people_id) {
    return request.post("./index.php", {
        function: "removeEntryPeople",
        review_entry_id,
        entry_people_id,
    });
}

export function updateRegistration(params) {
    return request.post("./index.php", {
        function: "updateRegistration",
        ...params,
    });
}

export function getRegistrations(params) {
    return request.post("./index.php", {
        function: "getRegistrations",
        ...params,
    });
}
export function addActivityToReviewEntry(review_entry_id, activity_id) {
    return request.post("./index.php", {
        function: "addActivityToReviewEntry",
        review_entry_id,
        activity_id,
    });
}
export function removeActivityFromReviewEntry(review_entry_id, activity_id) {
    return request.post("./index.php", {
        function: "removeActivityFromReviewEntry",
        review_entry_id,
        activity_id,
    });
}
export function getReviewEntryActivities(review_entry_id) {
    return request.post("./index.php", {
        function: "getReviewEntryActivities",
        review_entry_id,
    });
}
export function updateReviewEntryActivityDataSequence(review_entry_id, ids) {
    return request.post("./index.php", {
        function: "updateReviewEntryActivityDataSequence",
        review_entry_id,
        ids,
    });
}
export function updateTeamActivity(params) {
    return request.post("./index.php", {
        function: "updateTeamActivity",
        review_entry_id: params.review_entry_id,
        user_id: params.user_id,
        id: params.id,
        type: params.type,
        position: params.position,
        org: params.org,
        desc: params.desc,
        hours_peer_week: params.hours_peer_week,
        start_time: params.start_time,
        end_time: params.end_time,
        attachmentUrl: params.attachmentUrl,
    });
}
export function removeTeamActivityFromReviewEntry(
    review_entry_id,
    activity_id
) {
    return request.post("./index.php", {
        function: "removeTeamActivityFromReviewEntry",
        review_entry_id,
        activity_id,
    });
}

export function getPayUrl(params) {
    return request.post("./index.php", {
        function: "getOxPayQrUrl",
        ...params,
    });
}
export function getTypes() {
    return request.post("./index.php", {
        function: "getTypes",
    });
}

export function getContactTypes(contact_id) {
    return request.post("./index.php", {
        function: "getContactTypes",
        contact_id,
    });
}

export function getTypeInfo(type_id) {
    return request.post("./index.php", {
        function: "getTypeInfo",
        type_id,
    });
}

export function getupdateReviewEntryStatus(user_id, entry_type) {
    return request.post("./index.php", {
        function: "getupdateReviewEntryStatus",
        user_id,
        entry_type,
    });
}

export function removeActivity(params) {
    return request.post("./index.php", {
        function: "removeActivity",
        ...params,
    });
}
export function saveAppForm(form) {
    return request.post("./index.php", {
        function: "saveAppForm",
        form,
    });
}

export function addStudentInDatabase(
    account_id,
    entry_id,
    lastName,
    givenName,
    lastName_pinyin,
    givenName_pinyin,
    gender,
    graduation,
    student_pwd,
    mobile,
    email
) {
    return request.post("./index.php", {
        function: "addStudentInDatabase",
        account_id,
        entry_id,
        lastName,
        givenName,
        lastName_pinyin,
        givenName_pinyin,
        gender,
        graduation,
        student_pwd,
        mobile,
        email,
    });
}

export function addStudentInDatabaseV2(
    account_id,
    contact_id,
    category_id,
    entry_type,
    lastName,
    givenName,
    lastName_pinyin,
    givenName_pinyin,
    gender,
    graduation,
    student_pwd,
    mobile,
    email,
    ownSchool
) {
    return request.post("./index.php", {
        function: "addStudentInDatabaseV2",
        account_id,
        contact_id,
        category_id,
        entry_type,
        lastName,
        givenName,
        lastName_pinyin,
        givenName_pinyin,
        gender,
        graduation,
        student_pwd,
        mobile,
        email,
        ownSchool,
    });
}

export function getContactInfo(contact_id) {
    return request.post("./index.php", {
        function: "getContactInfo",
        contact_id,
    });
}

export function getAccounts() {
    return request.post("./index.php", {
        function: "getAccounts",
    });
}

export function getAccount(account_id) {
    return request.post("./index.php", {
        function: "getAccount",
        account_id,
    });
}

export function getProjectInfoByCode(project_code) {
    return request.post("./index.php", {
        function: "getProjectInfoByCode",
        project_code,
    });
}

export function saveAccountForm(form, projetc_type) {
    return request.post("./index.php", {
        function: "saveAccountForm",
        form,
        projetc_type,
    });
}

export function saveNominatorForm(form) {
    return request.post("./index.php", {
        function: "saveNominatorForm",
        form,
    });
}


export function updateAttachUrl(params) {
    return request.post("./index.php", {
        function: "updateReviewEntryAttachment",
        ...params,
    });
}

export function getUserProfile(params) {
    return request.post("./index.php", {
        function: "getMyProfile",
        ...params,
    });
}

export function getMessageList(student_id) {
    return request.post("./index.php", {
        function: "getMessageList",
        student_id,
    });
}

export function sendMessage(student_id, content) {
    return request.post("./index.php", {
        function: "sendMessage",
        student_id,
        content,
    });
}
export function checkRegistration(student_id) {
    return request.post("./index.php", {
        function: "checkRegistration",
        student_id,
    });
}

export function starActivityOptions(student_id, option_id, status) {
    return request.post("./index.php", {
        function: "starActivityOptions",
        student_id,
        option_id,
        status,
    });
}
export function searchActivityOptions(
    student_id,
    searchKey,
    searchType,
    status
) {
    return request.post("./index.php", {
        function: "searchActivityOptions",
        student_id,
        searchKey,
        searchType,
        status,
    });
}
export function getAccountForms() {
    return request.post("./index.php", {
        function: "getAccountForms",
    });
}
export function getTutorialVideoList() {
    return request.post("./index.php", {
        function: "getTutorialVideoList",
    });
}
export function loginByAdvisor(params) {
    return request.post("./index.php", {
        function: "loginByAdvisor",
        ...params,
    });
}

export function getApplicationsByAdvisor(params) {
    return request.post("./index.php", {
        function: "getApplicationsByAdvisor",
        ...params,
    });
}

export function removeReviewEntry(entry_id) {
    return request.post("./index.php", {
        function: "removeReviewEntry",
        entry_id,
    });
}
export function getQiniuToken() {
    return request.post("./index.php", {
        function: "getQiniuToken",
    });
}
export function getSingaporeQiniuToken() {
    return request.post("./index.php", {
        function: "getSingaporeQiniuToken",
    });
}
export function getCategoryInfoAndMaterials(student_id, category_id) {
    return request.post("./index.php", {
        function: "getCategoryInfoAndMaterials",
        student_id,
        category_id,
    });
}
export function updateCategoryMaterialByUser(
    student_id,
    category_id,
    materials
) {
    return request.post("./index.php", {
        function: "updateCategoryMaterialByUser",
        student_id,
        category_id,
        materials,
    });
}
export function addCurrentLoginStudentToEntryPeople(
    student_id,
    review_entry_id
) {
    return request.post("./index.php", {
        function: "addCurrentLoginStudentToEntryPeople",
        student_id,
        review_entry_id,
    });
}
export function getReviewEntries() {
    return request.post("./index.php", {
        function: "getReviewEntries",
    });
}
export function addContactRecommend(params) {
    return request.post("./index.php", {
        function: "addContactRecommend",
        ...params,
    });
}
export function getReviewEntryV1(id) {
    return request.post("./index.php", {
        function: "getReviewEntryV1",
        id,
    });
}
export function getReviewEntriesByUserIdV1(user_id) {
    return request.post("./index.php", {
        function: "getReviewEntriesByUserIdV1",
        user_id,
    });
}

export function getStudentsByAdvisorRecommended(params) {
    return request.post("./index.php", {
        function: "getStudentsByAdvisorRecommended",
        ...params,
    });
}

export function getAdvisorRecommendedStudentsByCategoryId(params) {
    return request.post("./index.php", {
        function: "getAdvisorRecommendedStudentsByCategoryId",
        ...params,
    });
}

export function nominateStudentByAdvisor(params) {
    return request.post("./index.php", {
        function: "nominateStudentByAdvisor",
        ...params,
    });
}

export function getNominationStudentsByAdvisor(params) {
    return request.post("./index.php", {
        function: "getNominationStudentsByAdvisor",
        ...params,
    });
}

export function collectCategory(params) {
    return request.post("./index.php", {
        function: "collectCategory",
        ...params,
    });
}

export function cancelCollectCategory(params) {
    return request.post("./index.php", {
        function: "cancelCollectCategory",
        ...params,
    });
}

export function getStudentMemberShipExpiredDate(params) {
    return request.post("./index.php", {
        function: "getStudentMemberShipExpiredDate",
        ...params,
    });
}

export function getSubCategoryList(category_id) {
    return request.post("./index.php", {
        function: "getSubCategoryList",
        category_id,
    });
}

export function getReviewEntriesCountsByProject(student_id) {
    return request.post("./index.php", {
        function: "getReviewEntriesCountsByProject",
        student_id,
    });
}


export function checkStudentIsInternationnal(student_id) {
    return request.post("./index.php", {
        function: "checkStudentIsInternationnal",
        student_id,
    });
}

export function getContactRecommendInfo(contact_id, student_id, entry_id) {
    return request.post("./index.php", {
        function: "getContactRecommendInfo",
        contact_id,
        student_id,
        entry_id
    });
}

export function getAdvisorStudentEntryList(user_id) {
    return request.post("./index.php", {
        function: "getAdvisorStudentEntryList",
        user_id
    });
}

export function getPaymentByApplication(application_id) {
    return request.post('./index.php', {
        function: 'getPaymentByApplication',
        application_id
    })
}

export function getCurrentProjectManage() {
    return request.post('./index.php', {
        function: 'getCurrentProjectManage',

    })
}

export function getStripeUrl(application_id, content, check_status) {
    return request.post('./index.php', {
        function: 'getStripeUrl',
        application_id,
        content,
        check_status
    })
}
export function checkCurrentYearApplication(student_id) {
    return request.post('./index.php', {
        function: 'checkCurrentYearApplication',
        student_id
    })
}

export function getApplicationInfo(application_id) {
    return request.post('./index.php', {
        function: 'getApplicationInfo',
        application_id
    })
}

export function removeApplication(application_id) {
    return request.post('./index.php', {
        function: 'removeApplication',
        application_id
    })
}

export function createUniversalPassOrder(params) {
    return request.post('./index.php', {
        function: 'createUniversalPassOrder',
        ...params
    })
}

export function getUPOrder(id) {
    return request.post('./index.php', {
        function: 'getUPOrder',
        id
    })
}


export function getUniversalPassStatus(student_id) {
    return request.post('./index.php', {
        function: 'getUniversalPassStatus',
        student_id
    })
}

export function getUPPortfolioList() {
    return request.post('./index.php', {
        function: 'getUPPortfolioList'
    })
}

export function searchAccounts(search_word, account_country) {
    return request.post('./index.php', {
        function: 'searchAccounts',
        search_word,
        account_country
    })
}

export function addApplicationByProject(params) {
    return request.post('./index.php', {
        function: 'addApplicationByProject',
        ...params
    })
}

export function sendEmailFroApplication(application_id) {
    return request.post('./index.php', {
        function: 'sendEmailForApplication',
        application_id
    })
}

export function sendEmailForPassOrder(order_id) {
    return request.post('./index.php', {
        function: 'sendEmailForPassOrder',
        order_id
    })
}

export function getCountries() {
    return request.post('./index.php', {
        function: 'getCountries'
    })
}

export function getStudentApplications(student_id) {
    return request.post('./index.php', {
        function: 'getStudentApplications',
        student_id
    })
}
export function getApplicationInfoNew(application_id) {
    return request.post('./index.php', {
        function: 'getApplicationInfoNew',
        application_id
    })
}


export function getFeatureAwards() {
    return request.post('./index.php', {
        function: 'getFeatureAwards'
    })
}

export function getOpenProducts(key_tag) {
    return request.post('./index.php', {
        function: 'getOpenProducts',
        key_tag
    })
}

export function getEAEProduct(id) {
    return request.post('./index.php', {
        function: 'getEAEProduct',
        id
    })
}

export function getProductPrice(product_id) {
    return request.post('./index.php', {
        function: 'getProductPrice',
        product_id
    })
}
export function getStudentReductionLevelByProject(student_id, project_code) {
    return request.post('./index.php', {
        function: 'getStudentReductionLevelByProject',
        project_code,
        student_id
    })
}

export function getNewsUpdateListByProject(project_id) {
    return request.post('./index.php', {
        function: 'getNewsUpdateListByProject',
        project_id
    })
}

export function replaceAcountry(old_country, new_country) {
    return request.post('./index.php', {
        function: 'replaceAcountry',
        old_country,
        new_country
    })
}

export function updateAccountCountry(account_id, account_country) {
    return request.post('./index.php', {
        function: 'updateAccountCountry',
        account_id,
        account_country
    })
}

export function createStudentApplication(student_id, project_id, application_channel) {
    return request.post('./index.php', {
        function: 'createStudentApplication',
        student_id,
        project_id,
        application_channel
    })
}
export function getOpenPassList(key_tag, request_type) {
    return request.post('./index.php', {
        function: 'getOpenPassList',
        key_tag,
        request_type
    })
}

export function getPass(id) {
    return request.post('./index.php', {
        function: 'getPass',
        id
    })
}

export function getPassPrice(pass_id) {
    return request.post('./index.php', {
        function: 'getPassPrice',
        pass_id
    })
}

export function checkApplication(application_id) {
    return request.post('./index.php', {
        function: 'checkApplication',
        application_id
    })
}


export function createREPassOrder(student_id, pass_id, order_channel) {
    return request.post('./index.php', {
        function: 'createREPassOrder',
        student_id,
        pass_id,
        order_channel
    })
}

export function getREPassOrder(order_id) {
    return request.post('./index.php', {
        function: 'getREPassOrder',
        order_id
    })
}

export function getStudentValidPasses(application_id) {
    return request.post('./index.php', {
        function: 'getStudentValidPasses',
        application_id
    })
}


export function verifyPass(pass_id, application_id) {
    return request.post('./index.php', {
        function: 'verifyPass',
        pass_id,
        application_id
    })
}

export function loginByAccountAdmin(admin_email, admin_password) {
    return request.post('./index.php', {
        function: 'loginByAccountAdmin',
        admin_email,
        admin_password
    })
}


export function getSchoolRegistrations(account_id, target_type) {
    return request.post('./index.php', {
        function: 'getSchoolRegistrations',
        account_id,
        target_type
    })
}

export function getOpenProjects(account_id, target_type) {
    return request.post('./index.php', {
        function: 'getOpenProjects',
        account_id,
        target_type
    })
}
export function getServicePrograms(key_tag, target_type) {
    return request.post('./index.php', {
        function: 'getServicePrograms',
        key_tag,
        target_type
    })
}

export function getOpenNewsUpdateList() {
    return request.post('./index.php', {
        function: 'getOpenNewsUpdateList'
    })
}

export function getStoreProjects(key_tag, target_type) {
    return request.post('./index.php', {
        function: 'getStoreProjects',
        key_tag,
        target_type
    })
}


export function getREProjectInfo(id) {
    return request.post('./index.php', {
        function: 'getREProjectInfo',
        id
    })
}

export function addAccountAdvisor(application_id, advisor_id) {
    return request.post('./index.php', {
        function: 'addAccountAdvisor',
        application_id,
        advisor_id
    })
}

export function getStudentAppsByAccount(account_id, project_id) {
    return request.post('./index.php', {
        function: 'getStudentAppsByAccount',
        account_id,
        project_id,
    })
}
export function checkAccountApplication(account_id, project_id) {
    return request.post('./index.php', {
        function: 'checkAccountApplication',
        account_id,
        project_id,
    })
}
export function addAccountApplication(account_id, project_id) {
    return request.post('./index.php', {
        function: 'addAccountApplication',
        account_id,
        project_id,
    })
}
export function getContactsByAccount(account_id) {
    return request.post('./index.php', {
        function: 'getContactsByAccount',
        account_id
    })
}
export function removeAccountContact(contact_id) {
    return request.post('./index.php', {
        function: 'removeAccountContact',
        contact_id
    })
}
export function getContactSubjects(account_id) {
    return request.post('./index.php', {
        function: 'getContactSubjects',
        account_id
    })
}
export function updateAccountContact(
    contact_givenName,
    contact_lastName,
    contact_email,
    contact_prefix,
    contact_discipline,
    contact_position,
    account_id,
    id) {
    return request.post('./index.php', {
        function: 'updateAccountContact',
        contact_givenName,
        contact_lastName,
        contact_email,
        contact_prefix,
        contact_discipline,
        contact_position,
        account_id,
        id,
    })
}
export function getStudentPurchasdPasses(student_id, ) {
    return request.post('./index.php', {
        function: 'getStudentPurchasdPasses',
        student_id,
    })
}
export function updateStudentInfo(
    student_id,
    student_lastName_pinyin,
    student_givenName_pinyin,
    student_gender,
    student_graduation
) {
    return request.post('./index.php', {
        function: 'updateStudentInfo',
        student_id,
        student_lastName_pinyin,
        student_givenName_pinyin,
        student_gender,
        student_graduation
    })
}


export function checkEnableStudentSignupProject(student_id, project_id) {
    return request.post('./index.php', {
        function: 'checkEnableStudentSignupProject',
        student_id,
        project_id
    })
}
export function removeStudentPassOrder(id) {
    return request.post('./index.php', {
        function: 'removeStudentPassOrder',
        id,
    })
}

export function addStudentInDatabaseV3(params) {
    return request.post('./index.php', {
        function: 'addStudentInDatabaseV3',
        ...params
    })
}
export function createLoginOPT(student_email) {
    return request.post('./index.php', {
        function: 'createLoginOPT',
        student_email
    })
}
export function loginByStudentV2(student_email, verify_code) {
    return request.post('./index.php', {
        function: 'loginByStudentV2',
        student_email,
        verify_code
    })
}

export function createApplicationByProjectAndAccount(params) {
    return request.post('./index.php', {
        function: 'createApplicationByProjectAndAccount',
        ...params
    })
}

export function updateStudentSubmission(params) {
    return request.post('./index.php', {
        function: 'updateStudentSubmission',
        ...params
    })
}

export function getStudentSubmissionByApplication(application_id) {
    return request.post('./index.php', {
        function: 'getStudentSubmissionByApplication',
        application_id
    })
}

export function getNIMProjects() {
    return request.post('./index.php', {
        function: 'getNIMProjects'
    })
}
export function saveAccountFormV1(form) {
    return request.post("./index.php", {
        function: "saveAccountFormV1",
        form
    });
}
export function updateAccountApplicationContacts(application_id, new_contacts) {
    return request.post("./index.php", {
        function: "updateAccountApplicationContacts",
        application_id,
        new_contacts
    });
}

export function getAccountReductionLevelByProject(account_id) {
    return request.post("./index.php", {
        function: "getAccountReductionLevelByProject",
        account_id
    });
}

export function submitReviewEntryDate(entry_id) {
    return request.post("./index.php", {
        function: "submitReviewEntryDate",
        entry_id
    });
}
export function getAccountContactCertificate(user_id) {
    return request.post("./index.php", {
        function: "getAccountContactCertificate",
        user_id
    });
}

export function getAccountApplicationsByContact(contact_id) {
    return request.post("./index.php", {
        function: "getAccountApplicationsByContact",
        contact_id
    });
}

export function getProjectInfoById(project_id) {
    return request.post("./index.php", {
        function: "getProjectInfoById",
        project_id
    });
}

export function updatePassPaySuccess(order_id) {
    return request.post("./index.php", {
        function: "updatePassPaySuccess",
        order_id
    });
}

export function getStudentInfo(student_id) {
    return request.post("./index.php", {
        function: "getStudentInfo",
        student_id
    });
}

export function getStudentTranscriptsByApplication(application_id) {
    return request.post("./index.php", {
        function: "getStudentTranscriptsByApplication",
        application_id
    });
}

export function getStudentAwardsByApplication(application_id) {
    return request.post("./index.php", {
        function: "getStudentAwardsByApplication",
        application_id
    });
}

export function uploadStudentTuitionTier(student_id, pass_id, url) {
    return request.post('./index.php', {
        function: 'uploadStudentTuitionTier',
        student_id,
        pass_id,
        url
    })
}

export function getStudentReductionLevelsByProject(student_id, project_code) {
    return request.post('./index.php', {
        function: 'getStudentReductionLevelsByProject',
        project_code,
        student_id
    })
}